<template>
  <div class="row">
    <div class="col-12 col-lg-5 c" v-if="current.status">
        <div class="card card-body">
            <div :class="'alert g text-center alert-' + current.color"><h3>{{ current.status }}</h3>
                <h2 v-if="current.name != ''">
                    {{ current.name }}
                </h2><br>
                <span v-html="current.number"></span>
            </div>
        </div>
    </div>
    <div class="col-12"></div>
    <div class="col-12 col-lg-5 c g">
        <div class="card card-body" style="padding:0px;">
            <input type="text" class="form-control form-control-lg" v-model="value" placeholder="اكتب رقم الهوية هنا..." style="text-align: center;">
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="checkBarcode()">
                فحص الان
            </button>
        </div>
    </div>
    <div class="attendsearchbyname" style="position: fixed; left: 0px; top: 100px;" v-if="!byname2">
        <button style="border-radius: 0px;" class="btn btn-sm btn-success" @click="byname = !byname">
            <i class="fa fa-search"></i>
            بحث بالاسم
            &nbsp;&nbsp;
        </button>
        <div class="card card-body" v-if="byname" style="width: 300px">
            <input type="text" class="form-control form-control-sm" v-model="name" placeholder="ابحث هنا...">
            <table class="table table-sm table-bordered table-hover">
                <tbody>
                    <template v-for="s in students">
                        <tr :key="s._id" v-if="s.name.includes(name) && name != ''" @click="value = s.number;checkBarcode()">
                            <td>{{ s.name }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
    <div class="attendsearchbyname2" style="position: fixed; left: 0px; top: 130px" v-if="!byname">
        <button style="border-radius: 0px;" class="btn btn-sm btn-warning" @click="byname2 = !byname2">
            <i class="fa fa-check-square"></i>
            الحالة: 
            <span v-if="custom_status == 'auto'">تلقائي</span>
            <span v-if="custom_status == 1">حاضر</span>
            <span v-if="custom_status == 2">متأخر</span>
            <span v-if="custom_status == 3">غائب</span>
            <span v-if="custom_status == 4">انصراف</span>
            <span v-if="custom_status == 5">غائب بعذر</span>
        </button>
        <div class="card card-body" v-if="byname2" style="width: 300px">
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="custom_status" value="auto">
                تلقائي حسب التوقيت
              </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="custom_status" :value="1">
                حاضر
              </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="custom_status" :value="2">
                متأخر
              </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="custom_status" :value="3">
                غائب
              </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="custom_status" :value="4">
                انصراف
              </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="custom_status" :value="5">
                غائب بعذر
              </label>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            logs: [],
            value: null,
            last_value: null,
            students: [],
            groups: [],
            current: {},
            user: JSON.parse(localStorage.getItem('user')),
            notes: {},
            byname: false,
            byname2: false,
            name: "",
            custom_status: "auto"
        }
    },
    beforeDestroy(){
    },
    created(){
        if(!checkPer("students|scan|manual")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        //
        $.post(api + '/user/students/groups', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.groups = r.response
            }
        }).fail(function(){
        })
        $.post(api + '/user/students/list', {
            jwt: this.user.jwt,
            page: 1,
            perpage: 10000
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.students = r.response
            }
        }).fail(function(){
            g.loading = false;
        })

        $.post(api + '/user/students/notes/list', {
            jwt: this.user.jwt,
            active: true
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                r.response.forEach(function(a){
                    if(!g.notes[a.number] && a.alert){
                        g.notes[a.number] = [];
                    }
                    if(a.alert){
                        g.notes[a.number].push(a);
                    }
                })
            }
        }).fail(function(){
            g.loading = false;
        })
         g.uploadLogs()
    },
    methods: {
        checkBarcode(){
            var g = this;
            var a2e = s => s.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
            var value = a2e(g.value);
            g.value = null;
            g.last_value = null;
            var c = {}
            g.students.forEach(function(a){
                if(a.number == value){
                    c = a
                }
            })
            g.current = {}
            if(!c.name){
                g.current = {
                    status: "الطالب غير موجود",
                    name: "",
                    color: 'danger',
                    number: value
                }
            }else{
                var gr = {}
                g.groups.forEach(function(a){
                    if(a._id == c.group_id){
                        gr = a;
                    }
                })
                if(!gr._id){
                    g.current = {
                        status: "الطالب ليس لديه مجموعة اعدادات",
                        name: "",
                        color: 'danger',
                        number: value
                    }
                }else{
                    var weekday = new Array(7);
                    weekday[0] = "Sunday";
                    weekday[1] = "Monday";
                    weekday[2] = "Tuesday";
                    weekday[3] = "Wednesday";
                    weekday[4] = "Thursday";
                    weekday[5] = "Friday";
                    weekday[6] = "Saturday";
                    if (
                    gr.days
                        .split(",")
                        .includes(weekday[new Date().getDay()])
                    ) {

                        var ada = new Date().toISOString().split("T")[0] + " ";

                        var current_time = Date.parse(
                            ada +
                            new Date()
                                .toLocaleTimeString("en-US", {
                                timeZone: "Asia/Riyadh",
                                hour12: false,
                                })
                                .split(":")[0] +
                            ":" +
                            new Date()
                                .toLocaleTimeString("en-US", {
                                timeZone: "Asia/Riyadh",
                                hour12: false,
                                })
                                .split(":")[1]
                        );

                        var sstatus = "0";
                        var sstatustext = "خارج وقت الدوام";

                        if (current_time >= Date.parse(ada + gr.end_date)) {
                            sstatus = 4;
                            sstatustext = "انصراف";
                        } else if (
                            current_time >= Date.parse(ada + gr.ghiab_date)
                        ) {
                            sstatus = 3;
                            sstatustext = "غياب";
                        } else if (
                            current_time >= Date.parse(ada + gr.late_date)
                        ) {
                            sstatus = 2;
                            sstatustext = "تأخر";
                        } else if (
                            current_time >= Date.parse(ada + gr.start_date)
                        ) {
                            sstatus = 1;
                            sstatustext = "حاضر";
                        }
                        if(g.custom_status != 'auto'){
                            sstatus = g.custom_status;
                            if(g.custom_status == 1){
                            sstatustext = "حاضر";
                            }
                            if(g.custom_status == 2){
                            sstatustext = "تأخر";
                            }
                            if(g.custom_status == 3){
                            sstatustext = "غياب";
                            }
                            if(g.custom_status == 4){
                            sstatustext = "انصراف";
                            }
                            if(g.custom_status == 5){
                            sstatustext = "غائب بعذر";
                            }
                        }
                        g.logs.unshift({
                            name: c.name,
                            number: c.number,
                            time: ada +
                            new Date()
                                .toLocaleTimeString("en-US", {
                                timeZone: "Asia/Riyadh",
                                hour12: false,
                                })
                                .split(":")[0] +
                            ":" +
                            new Date()
                                .toLocaleTimeString("en-US", {
                                timeZone: "Asia/Riyadh",
                                hour12: false,
                                })
                                .split(":")[1],
                            status: sstatus,
                            sstatustext: sstatustext,
                            custom_status: g.custom_status == 'auto' ? false : sstatus
                        });
                        localStorage.setItem("reports", JSON.stringify(g.logs));
                        g.current = {
                            status: sstatustext,
                            name: c.name,
                            color: sstatus.toString().replace("0", "secondary")
                            .toString().replace("1", "success")
                            .toString().replace("2", "warning text-dark")
                            .toString().replace("3", "danger")
                            .toString().replace("4", "success"),
                            number: value
                        }
                        if(g.notes[value]){
                            if(g.notes[value].length){
                                g.current = {
                                status: "هناك ملاحظة على الطالب",
                                name: c.name,
                                color: "danger",
                                number: "<h3><ul>"+g.notes[value].map(function(r){
                                    return "<li>"+r.content+"</li>"
                                }).join("")+"</ul></h3>"
                            }
                            }
                        }
                    }else{
                        g.current = {
                            status: "هذا اليوم غير مسموح بالفحص فيه",
                            name: "",
                            color: 'danger',
                            number: value
                        }
                    }
                }
            }
            setTimeout(() => {
                g.current = {}
            }, 5000);
        },
        uploadLogs(){
            var g = this;
            if(g.logs.length){
                $.post(api + '/user/students/reports/upload', {
                    jwt: this.user.jwt,
                    reports: JSON.stringify(g.logs.map(function(x){
                        return {
                            number: x.number,
                            time: x.time,
                            from: 'manual',
                            custom_status: x.custom_status
                        }
                    })),
                }).then(function(r){
                    if(r.status == 100){
                        g.logs = [];
                        localStorage.removeItem('reports');
                    }
                    setTimeout(() => {
                        g.uploadLogs()
                    }, 10000);
                }).fail(function(){
                    setTimeout(() => {
                        g.uploadLogs()
                    }, 10000);
                })
            }else{
                setTimeout(() => {
                    g.uploadLogs()
                }, 10000);
            }
        }
    }
}
</script>

<style>

</style>